import type { TooltipPlacement } from 'antd/es/tooltip'
import dynamic from 'next/dynamic'
import type { FC } from 'react'

import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import useToggle from '@hooks/useToggle/useToggle'

import MenuContainer from './components/MenuContainer'
import { RegionalMenuContainer } from './styles'

const RegionalMenuPopover = dynamic(() => import('./components/RegionalMenuPopover'))

export type RegionalMenuVariantType = 'classic' | 'default' | 'modern'

type Props = {
  placement?: TooltipPlacement
  variant?: RegionalMenuVariantType
}

export const RegionalMenu: FC<Props> = ({ placement = 'bottomRight', variant = 'default' }) => {
  const isPageInteraction = useAppSelector(selectIsPageInteraction)
  const {
    on: isOpenRegionalMenu,
    setValue: setIsOpenRegionalMenu,
    toggle: openRegionalMenu,
    toggleOff: closeRegionalMenu,
  } = useToggle()

  return (
    <RegionalMenuContainer>
      <MenuContainer isActive={isOpenRegionalMenu} onClick={openRegionalMenu} variant={variant} />
      {isPageInteraction && (
        <RegionalMenuPopover
          close={closeRegionalMenu}
          isOpen={isOpenRegionalMenu}
          placement={placement}
          setIsOpen={setIsOpenRegionalMenu}
        />
      )}
    </RegionalMenuContainer>
  )
}
